import { defineStore } from 'pinia'
import api from '@/api/index'

function notificationActions(name, channelName) {
  return {
    markRead: async function (notification) {
      try {
        await api.notifications.markRead(notification)
        this.collection = this.collection.filter(
          (item) => item.id !== notification.id
        )
        this.collectionMetaData.total_entries -= 1
      } catch (error) {
        throw new Error(error)
      }
    },
    postPone: async function (notification) {
      try {
        await api.notifications.postPone(notification)
        this.collection = this.collection.filter(
          (item) => item.id !== notification.id
        )
        this.collectionMetaData.total_entries -= 1
      } catch (error) {
        throw new Error(error)
      }
    },
    async handleError(error) {
      this.loading = false
      this.error = error
      return error
    },
    async loadChannel(num_items = 5) {
      console.log('Calling loadChannel')
      this.loading = true
      try {
        ;[this.collectionMetaData, this.collection] =
          await api.notifications.loadCollection({
            fields: ['title', 'body'],
            related: [],
            pageOptions: { items_per_page: num_items, page: 0 },
            filters: { channel: channelName },
          })
        this.loading = false
        this.error = ''
      } catch (error) {
        throw new Error(error)
      }
    },
  }
}

const getNotificationStore = (name, channelName) =>
  defineStore(name, {
    state: () => ({
      loading: true,
      error: false,
      collection: [],
      collectionMetaData: { total_entries: 0 },
    }),
    actions: notificationActions(name, channelName),
    getters: {
      notificationCount: (state) => state.collectionMetaData.total_entries,
    },
  })

export const useNotificationMessageStore = getNotificationStore(
  'notification-message',
  'message'
)
export const useNotificationHeaderMessageStore = getNotificationStore(
  'notification-header_message',
  'header_message'
)
export const useNotificationAlertStore = getNotificationStore(
  'notification-alert',
  'alert'
)
