<script setup>
  import { computed, ref } from 'vue'
  import { onClickOutside } from '@vueuse/core'
  import { storeToRefs } from 'pinia'

  import { useNotificationMessageStore } from '@/stores/notifications'
  import Icon from '@/components/Icon.vue'

  import NotificationList from './NotificationList.vue'
  import NotificationModal from './NotificationModal.vue'
  import { DROPDOWN_NUM_ENTRIES } from './const.js'

  const store = useNotificationMessageStore()
  const { collectionMetaData, collection } = storeToRefs(store)
  const { loadChannel, markRead, postPone } = store

  let buttonTitle = computed(() =>
    collection.value
      ? `Afficher ${collection.value.length} notifications`
      : 'Aucune notification'
  )

  const childrenVisible = ref(false)
  const alertVisible = ref(false)

  function toggleVisible() {
    childrenVisible.value = !childrenVisible.value
  }
  function showAlert() {
    console.log('Showing the popup with all notifications')
    alertVisible.value = true
    childrenVisible.value = false
  }
  function hideAlert() {
    alertVisible.value = false
    childrenVisible.value = false
  }
  const dropDownTarget = ref(null)
  onClickOutside(dropDownTarget, (event) => {
    if (!childrenVisible) return
    if (!alertVisible.value) {
      childrenVisible.value = false
    }
  })
  const onMarkRead = (notification) => {
    markRead(notification)
  }
  const onPostpone = (notification) => {
    postPone(notification)
  }
  // Load notifications destinated to the message channel
  loadChannel(DROPDOWN_NUM_ENTRIES)
</script>
<template>
  <div class="notification_counter">
    <button
      class="icon unstyled"
      :title="buttonTitle"
      :aria-label="buttonTitle"
      @click="toggleVisible"
      :disabled="!store.notificationCount"
      :key="store.notificationCount"
    >
      <Icon name="bell" />
      <span v-if="store.notificationCount > 0" class="bubble">{{
        store.notificationCount
      }}</span>
    </button>
  </div>
  <NotificationList
    v-if="childrenVisible"
    :notifications="collection"
    :notificationCount="store.notificationCount"
    @show-all="showAlert"
    @mark_read="onMarkRead"
    @postpone="onPostpone"
    ref="dropDownTarget"
  />
  <NotificationModal
    v-if="alertVisible"
    ref="alertTarget"
    @close="hideAlert"
    @mark_read="onMarkRead"
    @postpone="onPostpone"
  />
</template>
