<script setup>
  import { useNotificationHeaderMessageStore } from '@/stores/notifications'
  import Notification from './components/header_message/Notification.vue'
  import { storeToRefs } from 'pinia'
  import { computed } from 'vue'

  // Load the latest notification
  const store = useNotificationHeaderMessageStore()
  const { collectionMetaData, collection } = storeToRefs(store)
  const onMarkRead = (notification) => {
    store.markRead(notification).then(() => store.loadChannel(1))
  }

  store.loadChannel(1)
  // collection est réactive, mais collection[0] ne l'est pas
  // On utilise donc une valeur "computed" pour avoir de la réactivité
  const notification = computed(() => {
    return collection.value[0]
  })
</script>
<template>
  <Notification
    v-if="collectionMetaData.total_entries && !loading"
    :notification="notification"
    @mark_read="onMarkRead"
  />
</template>
