<script setup>
  import Notification from './Notification.vue'
  import { DROPDOWN_NUM_ENTRIES } from './const.js'

  const props = defineProps({
    notifications: Array,
    notificationCount: Number,
  })
  const emits = defineEmits(['showAll', 'mark_read', 'postpone'])
  const unvisibleCount = Math.max(
    props.notificationCount - DROPDOWN_NUM_ENTRIES,
    0
  )
  const message = `... et ${unvisibleCount} autres notifications`
  const showAll = () => {
    emits('showAll')
    return false
  }
</script>
<template>
  <ul class="notifications">
    <Notification
      v-for="notification of notifications.slice(0, DROPDOWN_NUM_ENTRIES)"
      :key="notification.id"
      :notification="notification"
      @mark_read="(notification) => $emit('mark_read', notification)"
      @postpone="(notification) => $emit('postpone', notification)"
    />
    <li v-if="unvisibleCount" class="text_more">
      <div>
        <small>{{ message }}</small>
        <br />
        <a href="javascript:void(0);" @click="showAll"
          >Voir toutes les notifications</a
        >
      </div>
    </li>
  </ul>
</template>
