import { startApp } from '@/helpers/utils'
import MessageApp from './MessageApp.vue'
import AlertApp from './AlertApp.vue'
import HeaderMessageApp from './HeaderMessageApp.vue'

const messageApp = startApp(MessageApp, 'vue-notification-message-app')
const alertApp = startApp(AlertApp, 'vue-notification-alert-app')
const headerMessageApp = startApp(
  HeaderMessageApp,
  'vue-notification-header_message-app'
)
