<script setup>
  import { ref } from 'vue'
  import { onClickOutside } from '@vueuse/core'

  import { storeToRefs } from 'pinia'
  import { useNotificationMessageStore } from '@/stores/notifications'
  import Notification from './Notification.vue'
  import Icon from '@/components/Icon.vue'
  import ModalLayout from '@/layouts/ModalLayout.vue'

  const emit = defineEmits(['close', 'mark_read', 'postpone'])
  const store = useNotificationMessageStore()
  // Load notifications destinated to the message channel
  const { collectionMetaData, collection } = storeToRefs(store)

  const modalTarget = ref(null)
  onClickOutside(modalTarget, (event) => {
    emit('close')
  })

  const onMarkRead = (notification) => {
    emit('mark_read', notification)
  }
  const onPostpone = (notification) => {
    emit('postpone', notification)
  }
  await store.loadChannel(1000)
</script>
<template>
  <ModalLayout @close="$emit('close')" ref="modalTarget">
    <template v-slot:header>{{ collection.length }} Notifications</template>
    <template v-slot:body>
      <ul class="notifications">
        <Notification
          v-for="notification of collection"
          :key="notification.id"
          :notification="notification"
          @mark_read="() => onMarkRead(notification)"
          @postpone="() => onPostpone(notification)"
        />
      </ul>
    </template>
    <template v-slot:footer>
      <button
        type="button"
        @click="$emit('close')"
        title="Fermer cette fenêtre"
        aria-label="Fermer cette fenêtre"
      >
        Fermer
      </button>
    </template>
  </ModalLayout>
</template>
