<script setup>
  import Icon from '@/components/Icon.vue'
  import { uniqueId } from '../helpers/utils'
  const props = defineProps({
    size: { type: String, default: 'middle' },
  })
  const emit = defineEmits(['close'])
  const uid = uniqueId()
  const onClose = () => emit('close')

  const css = 'modal_view ' + 'size_' + props.size
</script>
<template>
  <section :class="css">
    <div
      role="dialog"
      :id="uid"
      aria-modal="true"
      :aria-labelledby="'modal' + uid + '_title'"
      class="appear"
    >
      <div class="modal_layout">
        <header>
          <button
            type="button"
            class="icon only unstyled close"
            title="Masquer cette fenêtre"
            aria-label="Masquer cette fenêtre"
            @click="() => onClose()"
          >
            <Icon name="times"></Icon>
          </button>
          <h2 :id="'modal' + uid + '_title'">
            <slot name="header"></slot>
          </h2>
        </header>

        <div class="modal_content_layout">
          <div class="modal_content">
            <slot name="body"></slot>
          </div>

          <footer>
            <slot name="footer"></slot>
          </footer>
        </div>
      </div>
    </div>
  </section>
</template>
