<script setup>
  import ModalLayout from '@/layouts/ModalLayout.vue'

  const props = defineProps({
    notification: Object,
  })
  const emit = defineEmits(['postpone', 'mark_read'])
</script>
<template>
  <ModalLayout @close="$emit('mark_read', notification)">
    <template v-slot:header><span v-html="notification.title"></span></template>
    <template v-slot:body><span v-html="notification.body"></span></template>
    <template v-slot:footer>
      <button
        type="button"
        class="btn btn-primary"
        @click="$emit('mark_read', notification)"
        title="Fermer cette fenêtre"
        aria-label="Fermer cette fenêtre"
      >
        Fermer
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        @click="$emit('postpone', notification)"
        title="Me le rappeler plus tard"
        aria-label="Me le rappeler plus tard"
      >
        Plus tard
      </button>
    </template>
  </ModalLayout>
</template>
