<script setup>
  import IconSpan from '@/components/IconSpan.vue'
  const props = defineProps({
    notification: Object,
  })
  const emit = defineEmits(['mark_read'])
  const rawHtml = props.notification.title + '<br />' + props.notification.body
</script>
<template>
  <div class="notification">
    <div class="alert alert-warning align_center">
      <IconSpan name="exclamation-triangle" alt="Attention : " />
      <div>
        <strong v-html="rawHtml"></strong>
      </div>
      <button
        type="button"
        class="icon only unstyled close"
        title="Masquer ce message"
        aria-label="Masquer ce message"
        @click="$emit('mark_read', notification)"
      >
        <svg class=""><use href="/static/icons/endi.svg#times"></use></svg>
      </button>
    </div>
  </div>
</template>
