<script setup>
  import Icon from '@/components/Icon.vue'
  import IconSpan from '@/components/IconSpan.vue'

  const props = defineProps({
    notification: Object,
  })
  const emits = defineEmits(['mark_read', 'postpone'])

  const iconDict = {
    success: 'check',
    valid: 'check-circle',
    error: 'exclamation-triangle',
    invalid: 'times-circle',
    caution: 'exclamation-triangle',
    neutral: 'info-circle',
    calendar: 'calendar-alt',
  }
  const cssDict = {
    success: 'success',
    valid: 'success',
    error: 'invalid',
    caution: 'caution',
    neutral: 'neutral',
    calendar: 'neutral',
  }
  let iconName = iconDict[props.notification.status_type] || 'info-circle'
  let cssClass = cssDict[props.notification.status_type] || 'neutral'
  const postPone = () => {
    emits('postpone', props.notification)
  }
  const markRead = () => {
    emits('mark_read', props.notification)
  }
</script>
<template>
  <li aria-hidden="false" :class="cssClass">
      <IconSpan :name="iconName" alt="" />
    <div>
      <h4 class="text" v-html="notification.title"></h4>
      <div class="text" v-html="notification.body"></div>
    </div>

    <button
      class="icon unstyled close"
      title="Fermer cette notification"
      aria-label="Fermer cette notification"
      @click="markRead"
    >
      <Icon name="times" />
    </button>
    <button
      class="icon unstyled repeat"
      title="Répéter cette notification dans 7 jours"
      aria-label="Répéter cette notification dans 7 jours"
      @click="postPone"
    >
      <Icon name="clock" />
    </button>
  </li>
</template>
