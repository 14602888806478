<script setup>
  import { Suspense } from 'vue'
  import NotificationListComponent from './components/message/NotificationListComponent.vue'
</script>

<template>
  <Suspense>
    <NotificationListComponent />
    <template #fallback> Chargement... </template>
  </Suspense>
</template>
