<script setup>
  import { storeToRefs } from 'pinia'
  import { useNotificationAlertStore } from '@/stores/notifications'
  import NotificationModal from './components/alert/NotificationModal.vue'

  const store = useNotificationAlertStore()
  const { collectionMetaData, collection } = storeToRefs(store)

  const onMarkRead = (notification) => {
    store.markRead(notification).then(() => store.loadChannel(1))
  }
  const onPostPone = (notification) => {
    store.postPone(notification).then(() => store.loadChannel(1))
  }

  store.loadChannel(1)
</script>
<template>
  <NotificationModal
    v-if="collectionMetaData.total_entries"
    :notification="collection[0]"
    @mark_read="onMarkRead"
    @postpone="onPostPone"
  />
</template>
